@import url("https://use.typekit.net/duh6nzo.css");

html {
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
strong,
b {
  font-weight: bolder;
}
body {
  color: #242424;
  margin: 0;
  font-size: 1em;
  font-family: "Proxima Nova W15";
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: 0.01071em;
  background-color: #ffffff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: industry, sans-serif;
}
:focus {
  outline: none;
}
a {
  text-decoration: none;
}

.header {
  padding: 4% 10% 0px 10%;
  height: 150px;
}

.main-content {
  padding: 6% 8% 2% 8%;
}

/* Icon CSS */

.closeIcon {
  color: #fff;
}

/* Navigation CSS */

.nav-header {
  background-color: #ffc425;
  padding: 0px !important;
  color: #242424;
  text-align: center;
}

a span.nav-link {
  color: #fff;
  text-decoration: none;
  margin-left: 10px;
}
/* Chapter CSS */

.chapter-header {
  color: #242424;
  text-align: center;
}

.chapter-container {
  margin-bottom: 50px;
}

.chapter-content {
  margin: 0px 5% 50px 5%;
}

/* Header CSS */

.table-header {
  text-align: left;
  color: #fff;
  background-color: #242424;
}

/* Icon Size CSS */

.icon-small {
  font-size: 0.875rem;
}

.icon-medium {
  font-size: 1rem;
}

.icon-large {
  font-size: 1.25rem;
}

/* Font Size CSS */

.font-small {
  font-size: 0.875rem;
}

.font-medium {
  font-size: 0.925rem;
}

.font-large {
  font-size: 1rem;
}

/* Spacing CSS */

.mt0 {
  margin-top: 0px;
}
.mt5 {
  margin-top: 5px;
}
.mt10 {
  margin-top: 10px;
}
.mt15 {
  margin-top: 15px;
}
.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}
.mt40 {
  margin-top: 40px;
}
.mt50 {
  margin-top: 50px;
}
.mt75 {
  margin-top: 75px;
}
.mt100 {
  margin-top: 100px;
}
.mb0 {
  margin-bottom: 0px;
}
.mb5 {
  margin-bottom: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb15 {
  margin-bottom: 15px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb40 {
  margin-bottom: 40px;
}
.mb50 {
  margin-bottom: 50px;
}
.mb75 {
  margin-bottom: 75px;
}
.mb100 {
  margin-bottom: 100px;
}
.pt10 {
  padding-top: 10px;
}
.pt15 {
  padding-top: 15px;
}
.pt20 {
  padding-top: 20px;
}
.pt40 {
  padding-top: 40px;
}
.pt50 {
  padding-top: 50px;
}
.pb10 {
  padding-bottom: 10px;
}
.pb15 {
  padding-bottom: 15px;
}
.pb20 {
  padding-bottom: 20px;
}
.pb40 {
  padding-bottom: 40px;
}
.pb50 {
  padding-bottom: 50px;
}

/* Typography CSS */

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

/* Text Color CSS */

.grey {
  color: #242424;
}
.black {
  color: #000;
}
.white {
  color: #fff;
}
.light-grey {
  color: #a6a6a6;
}
.yellow {
  color: #ffc425;
}

/* BG Color CSS */

.bg-grey {
  background-color: #242424;
}
.bg-black {
  background-color: #000;
}
.bg-gwhite {
  background-color: #fff;
}
.bg-glight-grey {
  background-color: #a6a6a6;
}
.bg-gyellow {
  background-color: #ffc425;
}

/* Alignment CSS */

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

/* Height CSS */

.h-100 {
  height: 100%;
}

.h-90 {
  height: 90%;
}

.h-50 {
  height: 50%;
}

.h-0 {
  height: 0px;
}

/* Box Shadow */

.top-shadow {
  box-shadow: 0 -5px 5px -5px #a6a6a6;
}

.right-shadow {
  box-shadow: 5px 0 5px -5px #a6a6a6;
}

.bottom-shadow {
  box-shadow: 0 5px 5px -5px #a6a6a6;
}

.left-shadow {
  box-shadow: -5px 0 5px -5px #a6a6a6;
}

.all-shadow {
  box-shadow: 0 0 5px #a6a6a6;
}

/* Text Decoration CSS */

.em {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.underline-none {
  text-decoration: none;
}

/* Custom Background Images */
.recommendation-main {
  background-color: #fff;
}

.form {
  padding: 2% 10% 5% 10%;
  background-color: #f8f8f8;
}

.loaderMessage {
  visibility: visible;
}

.header-1 {
  position: relative;
  margin-top: 20px;
  text-align: center;
  position: relative;
  font-size: 14px;
}

.header-2 {
  position: relative;
  text-align: left;
  position: relative;
  font-size: 36px;
  text-transform: uppercase;
}

.border-line {
  background: linear-gradient(
    #ffffff 0%,
    #ffffff 47%,
    #ffc425 50%,
    #ffc425 50%,
    #ffffff 52%,
    #ffffff 100%
  );
}

.border-line.right span {
  background: #fff;
  padding: 0 10px 0 0;
  position: relative;
  z-index: 5;
}

.border-line span {
  background: #fff;
  padding: 0 20px;
  position: relative;
  z-index: 5;
}

.error {
  color: red;
  padding-top: 10px;
  font-size: 12px;
  font-weight: 400;
}

.overflowHidden {
  overflow: hidden;
}

.fan-bg {
  width: 100%;
  background-position: 30% 16%;
}

/* Home CSS */

.main-header {
  height: 0;
  padding-top: 50%;
  opacity: 0.5;
}

.card {
  position: relative;
  background-color: #000 !important;
}

.overlay {
  position: absolute;
  top: 75%;
  right: 50px;
}

.overlayText {
  color: #ffc425;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 0px;
  font-size: 46px;
}

/* Case Study CSS */

.overlayCaseStudy {
  position: absolute;
  top: 55%;
  left: 50px;
}

.overlayCaseStudyText {
  color: #fff;
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 1.3em;
  line-height: 1.25;
  width: 70%;
  font-family: "Proxima Nova W15";
}

/* Responsive */

@media screen and (max-width: 768px) {
  body {
    color: #242424;
    margin: 0;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: 0.01071em;
    background-color: #ffffff;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: industry, sans-serif;
  }
  :focus {
    outline: none;
  }
  a {
    text-decoration: none;
  }

  .header {
    padding: 4% 5% 0px 5%;
    height: 150px;
  }

  .main-content {
    padding: 10% 5% 5% 5%;
  }

  /* Icon CSS */

  .closeIcon {
    color: #fff;
  }

  /* Navigation CSS */

  .nav-header {
    background-color: #ffc425;
    padding: 0px !important;
    color: #242424;
    text-align: center;
  }

  a span.nav-link {
    color: #fff;
    text-decoration: none;
    margin-left: 10px;
  }
  /* Chapter CSS */

  .chapter-header {
    color: #242424;
    text-align: center;
  }

  .chapter-container {
    margin-bottom: 50px;
  }

  .chapter-content {
    margin: 0px 5% 50px 5%;
  }

  /* Header CSS */

  .table-header {
    text-align: left;
    color: #fff;
    background-color: #242424;
  }

  /* Icon Size CSS */

  .icon-small {
    font-size: 0.875rem;
  }

  .icon-medium {
    font-size: 1rem;
  }

  .icon-large {
    font-size: 1.25rem;
  }

  /* Typography CSS */

  .uppercase {
    text-transform: uppercase;
  }

  .lowercase {
    text-transform: lowercase;
  }

  /* Alignment CSS */

  .center-mobile {
    text-align: center;
  }

  .left-mobile {
    text-align: center;
  }

  .right-mobile {
    text-align: center;
  }

  /* Height CSS */

  .h-100 {
    height: 100%;
  }

  .h-90 {
    height: 90%;
  }

  .h-50 {
    height: 50%;
  }

  .h-0 {
    height: 0px;
  }

  /* Box Shadow */

  .top-shadow {
    box-shadow: 0 -5px 5px -5px #a6a6a6;
  }

  .right-shadow {
    box-shadow: 5px 0 5px -5px #a6a6a6;
  }

  .bottom-shadow {
    box-shadow: 0 5px 5px -5px #a6a6a6;
  }

  .left-shadow {
    box-shadow: -5px 0 5px -5px #a6a6a6;
  }

  .all-shadow {
    box-shadow: 0 0 5px #a6a6a6;
  }

  /* Text Decoration CSS */

  .em {
    font-style: italic;
  }

  .underline {
    text-decoration: underline;
  }

  .underline-none {
    text-decoration: none;
  }

  /* Custom Background Images */
  .recommendation-main {
    background-color: #fff;
  }

  .form {
    padding: 2% 10% 5% 10%;
    background-color: #f8f8f8;
  }

  .loaderMessage {
    visibility: visible;
  }

  .error {
    color: red;
    padding-top: 10px;
    font-size: 12px;
    font-weight: 600;
  }

  /* Home Responsive CSS */

  .main-header {
    height: 0;
    padding-top: 50%;
    opacity: 0.5;
  }

  .header-2 {
    position: relative;
    text-align: left;
    position: relative;
    font-size: 24px;
    text-transform: uppercase;
  }

  .card {
    position: relative;
    background-color: #000;
  }

  .overlay {
    position: absolute;
    top: 70%;
    right: 20px;
  }

  .overlayText {
    color: #ffc425;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0px;
    font-size: 20px;
  }

  /* Dashboard Responsive CSS */

  .mobile-hide {
    display: none;
  }

  /* Case Study Responsive CSS */

  .overlayCaseStudy {
    position: absolute;
    top: 25%;
    left: 20px;
  }
  .overlayCaseStudyText {
    color: #fff;
    font-weight: 500;
    margin-bottom: 0px;
    font-size: 12px;
    line-height: 14px;
    width: 85%;
    font-family: "Proxima Nova W15";
  }
}

  /* Vertical Marketing Table */
  .tableFixHead          { margin-top: 1%;  overflow: auto; height: 100vh; margin-bottom: 1%;}
  .tableFixHead thead th { position: sticky; top: 0; z-index: 1;}

/* Fonts */

@font-face {
  font-family: "Proxima Nova W15";
  font-style: normal;
  src: local("Proxima Nova W15"),
    url(./fonts/ProximaNova-Reg.otf) format("truetype");
}

@font-face {
  font-family: "Proxima Nova W15";
  font-style: italic;
  src: local("Proxima Nova W15"),
    url(./fonts/ProximaNova-RegIt.otf) format("truetype");
}

@font-face {
  font-family: "industry";
  src: url("https://use.typekit.net/af/d6b712/00000000000000003b9adf58/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/d6b712/00000000000000003b9adf58/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/d6b712/00000000000000003b9adf58/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "industry";
  src: url("https://use.typekit.net/af/07284d/00000000000000003b9adf59/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/07284d/00000000000000003b9adf59/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/07284d/00000000000000003b9adf59/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "industry";
  src: url("https://use.typekit.net/af/6dc482/00000000000000003b9adf53/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/6dc482/00000000000000003b9adf53/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/6dc482/00000000000000003b9adf53/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "industry";
  src: url("https://use.typekit.net/af/16aae5/00000000000000003b9adf52/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/16aae5/00000000000000003b9adf52/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/16aae5/00000000000000003b9adf52/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}
